.t {
  transform: none;
}
[play-main] {
  padding-bottom: 8.75rem;
}
[play-main] section {
  position: relative;
}
[play-main] section h3 {
  font-size: 1.5rem;
  font-weight: 700;
}
[play-main] section.hero {
  height: 200vh;
}
[play-main] section.hero .screen {
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
[play-main] section.hero .holder {
  position: relative;
  width: 100%;
  height: 100%;
}
[play-main] section.hero img,
[play-main] section.hero video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
[play-main] section.hero .bg {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
[play-main] section.hero .visual {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
[play-main] section.hero .visual > div {
  position: relative;
  width: 100%;
  height: 100%;
}
[play-main] section.hero .visual a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
[play-main] section.hero .title {
  position: absolute;
  left: 0rem;
  bottom: 5.9375rem;
  right: auto;
  top: auto;
  width: 100%;
  text-align: center;
  pointer-events: none;
}
[play-main] section.hero .title .inner {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
[play-main] section.hero .title .inner h2 {
  font-size: 1.875rem;
  line-height: 0.09rem;
  letter-spacing: -0.03125rem;
  line-height: 1.44;
  font-weight: 700;
}
[play-main] section.hero .title .inner p {
  margin-top: 0.9375rem;
  font-size: 0.875rem;
  line-height: 0.09375rem;
  letter-spacing: -0.01875rem;
  line-height: 1.5;
  font-weight: 400;
}
[play-main] section.highlights {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  margin-top: 7.5rem;
  position: relative;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}
[play-main] section.highlights h3 {
  padding-bottom: 1.5rem;
}
[play-main] section.highlights .swiper-container {
  overflow: visible;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide {
  width: 100%;
  height: 33.875rem;
  max-width: 20.3125rem;
  margin-right: 0.75rem;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide:last-child {
  margin: 0;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner [cdn-img] {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity 0.6s ease;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner .over-video {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  text-transform: uppercase;
  font-family: 'Helvetica-Now-Display-Black', sans-serif;
  font-size: 4.25rem;
  line-height: 0.061875rem;
  line-height: 0.99;
  text-align: center;
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity 0.6s ease;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 4.375rem;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap [sns-share] {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.75rem;
  margin-right: 1.625rem;
  margin-bottom: 0.75rem;
  margin-left: 1.625rem;
  z-index: 9;
  position: absolute;
  right: 0rem;
  top: 0rem;
  left: auto;
  bottom: auto;
}
[play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap [sns-share] a.btn {
  background-image: url('/img/common/ic-share-more.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.no-touch [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item:hover [cdn-img],
.no-touch [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item:hover [cdn-video] {
  transform: scale(1.1);
}
.no-touch [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item:hover.has-video [cdn-img] {
  opacity: 0;
}
.no-touch [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item:hover p {
  opacity: 0;
  transform: scale(0.95);
}
[play-main] section.highlights .swiper-scrollbar {
  width: 100%;
  height: 0.125rem;
  background-color: #3e3e3e;
  margin-top: 2.5rem;
}
[play-main] section.highlights .swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #fff;
}
[play-main] section.highlights .swiper-navigation {
  display: none;
}
[play-main] section.categories {
  padding-top: 10rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
[play-main] section.categories h3 {
  padding-bottom: 2rem;
}
[play-main] section.categories ul li {
  margin-right: 2rem;
  display: inline-block;
}
[play-main] section.categories ul li:last-of-type {
  margin-right: 0rem;
}
[play-main] section.categories ul li a {
  font-family: 'Helvetica-Now-Display-Black', sans-serif;
  -webkit-text-stroke: 1px #fff;
  color: #000;
  font-size: 3.125rem;
  line-height: 4.125rem;
  transition: color 0.4s ease;
}
.no-touch [play-main] section.categories ul li a:hover {
  color: #fff;
}
@media (min-width: 768px) {
  [play-main] {
    padding-bottom: 10rem;
  }
  [play-main] section.hero .title {
    left: 0rem;
    bottom: 3.5rem;
    right: auto;
    top: auto;
  }
  [play-main] section.hero .title .inner {
    width: 65%;
  }
  [play-main] section.hero .title .inner h2 {
    font-size: 1.5rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.hero .title .inner p {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.highlights {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    margin-top: 6rem;
  }
  [play-main] section.highlights h3 {
    padding-bottom: 1.375rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide {
    width: 13.3125rem;
    height: 22.1875rem;
    max-width: none;
    margin-right: 1rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 2.75rem;
    line-height: 0.06125rem;
    line-height: 0.98;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap [sns-share] {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.75rem;
    margin-right: 1.75rem;
    margin-bottom: 0.75rem;
    margin-left: 1.75rem;
  }
  [play-main] section.highlights .swiper-scrollbar {
    display: none;
  }
  [play-main] section.highlights .swiper-navigation {
    display: block;
    position: absolute;
    right: 2.25rem;
    top: 0.5rem;
    left: auto;
    bottom: auto;
  }
  [play-main] section.highlights .swiper-navigation a {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: top;
    left: 0rem;
    top: 0rem;
    right: auto;
    bottom: auto;
    transform: translateX(0rem) translateY(0rem);
    margin: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    color: inherit;
    transition: opacity 0.2s ease;
  }
  .no-touch [play-main] section.highlights .swiper-navigation a:hover {
    opacity: 0.6;
  }
  [play-main] section.highlights .swiper-navigation a:after {
    display: none;
  }
  [play-main] section.highlights .swiper-navigation .swiper-button-prev {
    margin-right: 1.25rem;
    background-image: url('/img/common/ic-swipe-prev.png');
  }
  [play-main] section.highlights .swiper-navigation .swiper-button-next {
    background-image: url('/img/common/ic-swipe-next.png');
  }
  [play-main] section.categories {
    padding-top: 7.25rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  [play-main] section.categories h3 {
    padding-bottom: 3rem;
  }
  [play-main] section.categories ul li {
    margin-right: 2.25rem;
  }
  [play-main] section.categories ul li a {
    font-size: 4.25rem;
    line-height: 5.25rem;
    
  }
}
@media (min-width: 1024px) {
  [play-main] {
    padding-bottom: 12.5rem;
  }
  [play-main] section.hero .title {
    left: 0rem;
    bottom: 4.5rem;
    right: auto;
    top: auto;
  }
  [play-main] section.hero .title .inner h2 {
    font-size: 2rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.hero .title .inner p {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.highlights {
    margin-top: 6.75rem;
  }
  [play-main] section.highlights h3 {
    padding-bottom: 1.5rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide {
    width: 15.625rem;
    height: 26.125rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 3.25rem;
    line-height: 0.06125rem;
    line-height: 0.98;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap [sns-share] {
    margin-top: 0.75rem;
    margin-right: 1.75rem;
    margin-bottom: 0.75rem;
    margin-left: 1.75rem;
  }
  [play-main] section.categories {
    padding-top: 7.375rem;
  }
  [play-main] section.categories h3 {
    padding-bottom: 3rem;
  }
  [play-main] section.categories ul li {
    margin-right: 3.5rem;
  }
  [play-main] section.categories ul li a {
    font-size: 5.5rem;
    line-height: 6.625rem;
    
  }
}
@media (min-width: 1440px) {
  [play-main] {
    padding-bottom: 16.25rem;
  }
  [play-main] section h3 {
    font-size: 1.625rem;
  }
  [play-main] section.hero .title {
    left: 0rem;
    bottom: 6rem;
    right: auto;
    top: auto;
  }
  [play-main] section.hero .title .inner h2 {
    font-size: 2.4375rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.hero .title .inner p {
    margin-top: 1rem;
    font-size: 1.25rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.highlights {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 10rem;
  }
  [play-main] section.highlights h3 {
    padding-bottom: 2.25rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide {
    width: 22.0625rem;
    height: 36.75rem;
    margin-right: 1.5rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 4.625rem;
    line-height: 0.061875rem;
    line-height: 0.99;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap [sns-share] {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1rem;
    margin-right: 2.5rem;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
  }
  [play-main] section.highlights .swiper-navigation {
    right: 3rem;
    top: 0rem;
    left: auto;
    bottom: auto;
  }
  [play-main] section.highlights .swiper-navigation a {
    width: 2rem;
    height: 2rem;
  }
  [play-main] section.highlights .swiper-navigation .swiper-button-prev {
    margin-right: 1.5rem;
  }
  [play-main] section.categories {
    padding-top: 11.25rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  [play-main] section.categories h3 {
    padding-bottom: 3.75rem;
  }
  [play-main] section.categories ul li {
    margin-right: 5rem;
  }
  [play-main] section.categories ul li a {
    font-size: 7.8125rem;
    line-height: 9.0625rem;
    
  }
}
@media (min-width: 1600px) {
  [play-main] {
    padding-bottom: 17.5rem;
  }
  [play-main] section h3 {
    font-size: 2.25rem;
  }
  [play-main] section.hero .title {
    left: 0rem;
    bottom: 7.5rem;
    right: auto;
    top: auto;
  }
  [play-main] section.hero .title .inner h2 {
    font-size: 3.375rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.hero .title .inner p {
    margin-top: 1.5rem;
    font-size: 1.625rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.highlights {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    margin-top: 11.25rem;
  }
  [play-main] section.highlights h3 {
    padding-bottom: 3rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide {
    width: 23.1875rem;
    height: 41.5625rem;
    margin-right: 1.75rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .inner p {
    font-size: 5.25rem;
    line-height: 0.061875rem;
    line-height: 0.99;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap [sns-share] {
    width: 2rem;
    height: 2rem;
    margin-top: 1.1875rem;
    margin-right: 3.0625rem;
    margin-bottom: 1.1875rem;
    margin-left: 3.0625rem;
  }
  [play-main] section.highlights .swiper-navigation {
    right: 3.75rem;
  }
  [play-main] section.highlights .swiper-navigation a {
    width: 2.625rem;
    height: 2.625rem;
  }
  [play-main] section.highlights .swiper-navigation .swiper-button-prev {
    margin-right: 2rem;
  }
  [play-main] section.categories {
    padding-top: 12.5rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  [play-main] section.categories h3 {
    padding-bottom: 4.5rem;
  }
  [play-main] section.categories ul li {
    margin-right: 6.5rem;
  }
  [play-main] section.categories ul li a {
    font-size: 10.4375rem;
    line-height: 12.125rem;
    
  }
}
@media (min-width: 1921px) {
  [play-main] section.hero .title .inner h2 {
    font-size: 4.25rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide {
    width: 31.125rem;
    height: 55.3125rem;
    margin-right: 2.125rem;
  }
  [play-main] section.highlights .swiper-container .swiper-wrapper .swiper-slide .slider-item .sns-wrap [sns-share] {
    margin-top: 1.1875rem;
    margin-right: 3.375rem;
    margin-bottom: 1.1875rem;
    margin-left: 3.375rem;
  }
  [play-main] section.highlights .swiper-navigation a {
    width: 2.625rem;
    height: 2.625rem;
  }
  [play-main] section.highlights .swiper-navigation .swiper-button-prev {
    margin-right: 2rem;
  }
  [play-main] section.categories {
    padding-top: 12.5rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  [play-main] section.categories h3 {
    padding-bottom: 4.5rem;
  }
  [play-main] section.categories ul li {
    margin-right: 7.5rem;
  }
  [play-main] section.categories ul li a {
    font-size: 10.4375rem;
    line-height: 12.125rem;
    
  }
}
